import React, { useContext } from "react"

import { navigate } from "gatsby-link"

import Check from "../../assets/images/Icons/check.svg"
import { AuthenticationContext } from "../../context"
import "./RegisterSuccessComponent.scss"

const RegisterSuccessComponent = () => {
  const { email } = useContext(AuthenticationContext)

  const handleClick = () => {
    navigate("/home")
  }

  return (
    <div className="register-success-container">
      <div className="register-success-component">
        <div className="register-success-component__icon">
          <img src={Check} />
        </div>

        <div className="register-success-component__title">
          <p>
            Welcome to the Washroom <br />
            We have just sent a confirmation <br />
            email to {email}
            <br />
            <br />
            Please confirm this email to <br />
            activate your account.
          </p>
        </div>
        <div className="register-success-component__description"></div>
        <div
          className="register-success-component__button"
          onClick={handleClick}
        >
          Start Washing
        </div>
      </div>
    </div>
  )
}

export default RegisterSuccessComponent
