import React from "react"

import RegisterLayout from "../components/Layout/RegisterLayout"
import RegisterSuccessComponent from "../components/LoginRegister/RegisterSuccessComponent"

const RegisterSuccess = () => {
  return (
    <RegisterLayout isFooter={true}>
      <RegisterSuccessComponent />
    </RegisterLayout>
  )
}

export default RegisterSuccess
